import {
  createI18n,
  type IntlDateTimeFormats,
  type IntlNumberFormats,
  type LocaleMessageDictionary,
  type VueMessageType,
} from 'vue-i18n';


const getBrowserLanguage = () => (navigator.language.startsWith('es') ? 'es' : 'en-US');

const userLanguage =  getBrowserLanguage(); 

const values = {
  locale: userLanguage, 
  fallbackLocale: 'en-US',
  messages: {} as Record<string, LocaleMessageDictionary<VueMessageType>>,
  datetimeFormats: {} as Record<string, IntlDateTimeFormats>,
  numberFormats: {} as Record<string, IntlNumberFormats>,
  legacy: false,
  globalInjection: true
} as any;

// const files = import.meta.glob('./lang/*.js', { eager: true })
const files = import.meta.glob('@/locales/lang/*.js', { eager: true });


// for (const file in files) {
//   const locale = files[file].default;
//   values.messages[locale.key] = {
//     namespaced: true,
//     ...locale.strings,
//   };
//   values.datetimeFormats[locale.key] = locale.datetimeFormats;
//   values.numberFormats[locale.key] = locale.numberFormats;
// }

for (const file in files) {
  const locale = files[file].default;
  if (locale && locale.key && locale.strings) {
    // @ts-ignore
    values.messages[locale.key] = {
      namespaced: true,
      ...locale.strings,
    };
    values.datetimeFormats[locale.key] = locale.datetimeFormats || {};
    values.numberFormats[locale.key] = locale.numberFormats || {};
  }
}

const componentStrings = {
  messages: {} as Record<string, LocaleMessageDictionary<VueMessageType>>,
  datetimeFormats: {} as Record<string, IntlDateTimeFormats>,
};

Object.keys(componentStrings.messages).forEach((locale) => {
  values.messages[locale] = Object.assign({}, values.messages[locale], componentStrings.messages[locale]);
});


export const setLanguage = (lang: string) => {
  i18n.global.locale.value = lang;
  localStorage.setItem('userLanguage', lang); 
};

const observeHtmlLangChange = () => {
  const htmlElement = document.documentElement;

  const observer = new MutationObserver(() => {
    const newLang = htmlElement.getAttribute('lang');
    if (newLang && newLang !== i18n.global.locale.value) {
      setLanguage(newLang.startsWith('es') ? 'es' : 'en-US');
    }
    console.log('NEW LANG:', newLang)

  });


  observer.observe(htmlElement, { attributes: true, attributeFilter: ['lang'] });
};

setTimeout(() => {
  observeHtmlLangChange();
}, 100);

const i18n = createI18n(values);

export default i18n;