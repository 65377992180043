import { WrenchScrewdriverIcon } from '@heroicons/vue/16/solid';
import {
  AssetsInsightsCasesIcon,
  ComponentServiceLifeHealthIcon,
  RiskExplorerIcon,
  OverviewDashboardIcon,
  DataEnhancementIcon,
  AssetsIcon,
  FederalAssetsIcon,
  CalDetailsIcon,
  ReportingIcon,
  FleetInsightAssessmentIcon,
  CasesIcon,
  LightBulb,
  // WrenchScrewdriverIcon
} from '@uptake/uptake-component-library';

export const SiteSectionCustomPropertyValue = {
  OverviewDashboard: 'SectionLink_OverviewDashboard',
  RiskExplorer: 'SectionLink_RiskExplorer',
  DataRecon: 'SectionLink_DataRecon',
  Assets: 'SectionLink_Assets',
  Insights: 'SectionLink_Insights',
  Cases: 'SectionLink_Cases',
  GroupManagement: 'SectionLink_GroupManagement',
  AssetsInsightsCases: 'SectionLink_AssetsInsightsCases',
  WorkOrderAnalytics: 'SectionLink_WorkOrderAnalytics',
  Reporting: 'SectionLink_Reporting',
  RiskExplore: 'SectionLink_Reporting',
  CalculationDetails: 'CalculationDetails',
  FleetInsightAssessment: 'SectionLink_FleetInsightAssessment',
  AssetInsightsSummary: 'SectionLink_AssetInsightsSummary',
  RepeatedRepairs: 'SectionLink_RepeatedRepairs',
  RemoteDiagnostic: 'SectionLink_RemoteDiagnostic'
};

export const SiteSectionCustomProperty = {
  OverviewDashboard: '[data-cy=SectionLink_OverviewDashboard]',
  RiskExplorer: '[data-cy=SectionLink_RiskExplorer]',
  DataRecon: '[data-cy=SectionLink_DataRecon]',
  Assets: '[data-cy=SectionLink_Assets]',
  Insights: '[data-cy=SectionLink_Insights]',
  Cases: '[data-cy=SectionLink_Cases]',
  AssetsInsightsCases: '[data-cy=SectionLink_AssetsInsightsCases]',
  WorkOrderAnalytics: '[data-cy=SectionLink_WorkOrderAnalytics]',
  GroupManagement: '[data-cy=SectionLink_GroupManagement]',
  CalculationDetails: '[data-cy=sectionLink_CalculationDetails]',
  AssetInsightsSummary: 'SectionLink_assetInsightsSummary',
  RepeatedRepairs: 'SectionLink_repeatedRepairs',
  RemoteDiagnostic: 'SectionLink_RemoteDiagnostic'
};

export const SiteSectionHref = {
  OverviewDashboard: '/StrategyExplorer',
  RiskExplorer: '/RiskExplorer',
  DataRecon: '/DataRecon',
  Assets: '/Assets',
  Insights: '/Insights',
  Cases: '/Cases',
  GroupManagement: '/GroupManagement',
  AssetsInsightsCases: '/AssetsInsightsCases',
  WorkOrderAnalytics: '/WorkOrderAnalytics',
  Reporting: '/Reporting',
  CalculationDetails: '/CalculationDetails',
  FleetInsightAssessment: '/FleetInsightAssessment',
  AssetInsightsSummary: '/AssetInsightsSummary',
  RepeatedRepairs: '/RepeatedRepairs',
  RemoteDiagnostic: '/RemoteDiagnostic'
};

export const SiteSectionImageFilename = {
  AssetsInsightsCases: AssetsInsightsCasesIcon,
  WorkOrderAnalytics: ComponentServiceLifeHealthIcon,
  RiskExplorer: RiskExplorerIcon,
  OverviewDashboard: OverviewDashboardIcon,
  DataRecon: DataEnhancementIcon,
  Assets: AssetsIcon,
  FederalAssets: FederalAssetsIcon,
  Insights: CasesIcon,
  Cases: CasesIcon,
  GroupManagement: CasesIcon,
  Reporting: ReportingIcon,
  CalculationDetails: CalDetailsIcon,
  FleetInsightAssessment: FleetInsightAssessmentIcon,
  AssetInsightsSummary: LightBulb,
  RepeatedRepairs: CalDetailsIcon,
  RemoteDiagnostic: WrenchScrewdriverIcon
};

export default {
  SiteSectionCustomPropertyValue,
  SiteSectionCustomProperty,
  SiteSectionHref,
  SiteSectionImageFilename,
};