import axios, { type AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import useAuthStore from '@/features/auth/store/authStore';
class Client {
  public instance: AxiosInstance;
  public baseUrl: string;
  public isRefreshing = false;

  public constructor(baseUrl = '') {
    this.instance = axios.create({
      baseURL: baseUrl + '/api',
      withCredentials: import.meta.env.VITE_KEYCLOAK_DISABLED as unknown as boolean,
      headers: {
        Accept: 'application/json, text/plain, image/png',
      },
    });
    this.baseUrl = baseUrl;
    this.initializeResponseInterceptor();
    this.initializeRequestInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  };

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError);
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    try {
      const { token } = useAuthStore();
      if (config.headers) {
        config.headers['authorization'] = token;
      }
      return config;
    } catch (error: any) {
      throw new Error(`${(error.message, error.config)}`);
    }
  };

  public handleResponse = (response: AxiosResponse) => response;

  public handleError = (error: AxiosError) => {
    this.handleBadRequest(error);
    return Promise.reject(error);
  };

  public handleBadRequest = (error: AxiosError) => {
    if (error.response !== undefined && error.response.status === 400) {
      console.error('non auth error');
    }
    return Promise.reject(error);
  };
}

export default Client;
