import type { RouteRecordRaw } from 'vue-router';
import { UavRouteNames, UavRouteParameterNames } from './names';
import { defaultGuardProvider } from './helpers';

export default {
  path: `/asset/:${UavRouteParameterNames.assetId}`,
  name: 'Asset Viewer',
  beforeEnter: defaultGuardProvider(),
  component: () => import('@/features/uav/views/UAV.vue'),
  meta: {
    hideSidebar: false, // a temporary rollback of FFDI-3530, TODO
    title: 'UAV',
  },
  children: [
    {
      name: UavRouteNames.overview,
      path: 'overview',
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/uav/views/Overview.vue'),
      meta: {
        title: 'UAV - Overview',
      },
    },
    {
      name: UavRouteNames.insights,
      path: 'insights',
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/insights/views/InsightsUavView.vue'),
      meta: {
        title: 'UAV - Insights',
      },
    },
    {
      name: UavRouteNames.insightDetails,
      path: `insights/:${UavRouteParameterNames.assetInsightID}`,
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/insights/views/InsightDetailsUavView.vue'),
      meta: {
        title: 'UAV - Insight Details',
      },
    },
    {
      name: UavRouteNames.cases,
      path: 'cases',
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/cases/views/CasesUavView.vue'),
      meta: {
        title: 'UAV - Cases',
      },
    },
    {
      name: UavRouteNames.faults,
      path: 'faults',
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/uav/views/FaultsView.vue'),
      meta: {
        title: 'UAV - Faults',
      },
    },
    {
      name: UavRouteNames.remoteDiagnosticUav,
      path: `remoteDiagnosticUav`,
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/remoteDiagnostic/RemoteDiagnosticUavView.vue'),
  
      meta: {
        title: 'UAV - Remote Diagnostic Uav',
      },
    },
    {
      name: UavRouteNames.caseDetails,
      path: `cases/:${UavRouteParameterNames.caseID}`,
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/cases/views/CaseDetailsUavView.vue'),
      meta: {
        title: 'UAV - Case Details',
      },
    },
    {
      name: UavRouteNames.workOrders,
      path: 'workOrders',
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features//workOrder/workOrderAnalytics/views/WorkOrdersUavView.vue'),
      meta: {
        title: 'UAV - Work Orders',
      },
    },
    {
      name: UavRouteNames.workOrderDetails,
      path: `workOrders/:${UavRouteParameterNames.workOrderID}`,
      beforeEnter: defaultGuardProvider(),
      component: () => import('@/features/workOrder/workOrderAnalytics/views/WorkOrderDetailsUavView.vue'),
      meta: {
        title: 'UAV - Work Order Details',
      },
    },
  ],
} as RouteRecordRaw;
