import { API } from '@/api/client/apiClient';
import { insightSeverityEnum, insightTableType } from '@/features/insights/types/insights';
import type { Filter } from '@/features/userSavedFilters/types/userSavedFiltersTypes';
import { filterUtils } from '@/utils/filterUtils';
import { defineStore } from 'pinia';
import { buildQuery } from '@/utils/queryBuilder';
import useUserFiltersStore from '@/features/userSavedFilters/store/userFiltersStore';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';
import { useLocalStorage } from '@/utils/localStorageUtils';

export enum insightStatus {
  New = 'Awaiting Review',
  Accepted = 'Accepted',
  Disregarded = 'Disregarded',
  AssignedtoCase = 'Assigned to Case',
}

export const insightState = ['Active', 'Inactive'];
export const caseStatus = ['Closed', 'Open'];

const localStorageUtils = useLocalStorage();

type AssetsInsightsCasesObject = {
  loading: boolean;
  response: any;
  tableData: any;
  kpi: number;
  newKpi: number;
  filters: Filter[];
  filtersUav?: Filter[];
  filtersAic?: Filter[];
  filterCasesUav?: Filter[];
  defaultFilters?: Filter[];
  insightStartDateTo?: any;
  insightStartDateFrom?: any;
};

type AssetsInsightsCasesState = {
  insights: AssetsInsightsCasesObject;
  assets: AssetsInsightsCasesObject;
  cases: AssetsInsightsCasesObject;
  assetInsightSummaryFilter: Filter[],
  perPage: number;
  setToAll: boolean;
  setCasesToAll: boolean;
  openCasesByAssetCount: number;
};
export default defineStore('assetsInsightsCases', {
  state: (): AssetsInsightsCasesState => ({
    insights: {
      loading: true,
      response: undefined,
      tableData: undefined,
      kpi: 0,
      newKpi: 0,
      filters: [],
      filtersUav: [],
      filtersAic: [],
      defaultFilters: [],
      insightStartDateFrom: new Date(),
      insightStartDateTo: new Date()
    },
    assets: {
      loading: true,
      response: undefined,
      tableData: undefined,
      kpi: 0,
      newKpi: 0,
      filters: [],
    },
    cases: {
      loading: true,
      response: undefined,
      tableData: undefined,
      kpi: 0,
      newKpi: 0,
      filters: [],
      filterCasesUav: [],
    },
    assetInsightSummaryFilter: [],
    perPage: 100,
    openCasesByAssetCount: 0,
    setToAll: false,
    setCasesToAll: false,
  }),

  actions: {
    setInsightStartDates({ startDate, endDate }: { startDate: Date; endDate: Date }) {
      this.insights.insightStartDateFrom = startDate;
      this.insights.insightStartDateTo = endDate;
    },
    async updateCasesCountByAsset(assetId: any) {
      try {
        const assetDetails: any = await API.Assets.getAssetSummary(assetId);
        this.openCasesByAssetCount = assetDetails.casesSummary.openCases;
      } catch (error) {
        console.error('Error fetching asset details:', error);
      }
    },
    setAssetsInsightsCasesInsightsToNew() {
      this.insights.filters.forEach((filter: any) => {

        if (filter.tag === 'Insight Status') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Awaiting Review') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Awaiting Review'];
        } else if (filter.tag === 'Insight State') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Active') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Active'];
        } else {
          filter.options.forEach((option: any) => {
            option.selected = false;
          });
          filter.selectedItems = [];
        }
      });
      this.insights.filtersAic = this.insights.filters;
      useLocalStorage().setLocalStorage('filtersAic', JSON.stringify(this.insights.filters));
    },
    setAssetsInsightsCasesInsightsUavToNew() {
      this.insights.filters.forEach((filter: any) => {

        if (filter.tag === 'Insight Status') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Awaiting Review') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Awaiting Review'];
        } else {
          filter.options.forEach((option: any) => {
            option.selected = false;
          });
          filter.selectedItems = [];
        }
      });

        this.insights.filtersUav = this.insights.filters;
        useLocalStorage().setLocalStorage('filtersUav', JSON.stringify(this.insights.filters));
    },
    setAssetsInsightsCasesInsightsUavToActive() {
      this.insights.filters.forEach((filter: any) => {

        if (filter.tag === 'Insight State') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Active') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Active'];
        } else {
          filter.options.forEach((option: any) => {
            option.selected = false;
          });
          filter.selectedItems = [];
        }
      });

        this.insights.filtersUav = this.insights.filters;
        useLocalStorage().setLocalStorage('filtersUav', JSON.stringify(this.insights.filters));
    },
    setAssetsInsightsCasesInsightsToAll() {
      this.setToAll = true;
      localStorage.setItem('lastVisitedRoute', '/assetsInsightsCases/insights');
      this.insights.filters.forEach((filter: any) => {
        filter.options.forEach((option: any) => {
          option.selected = false;
        });
        filter.selectedItems = [];
      });
      this.insights.filtersAic = this.insights.filters;
      useLocalStorage().setLocalStorage('filtersAic', JSON.stringify(this.insights.filters));
    },
    setFilterForAicUav(insightTableType: insightTableType) {
      if (insightTableType === 'UAV') {
        this.insights.filters =
          this.insights.filtersUav.length > 0 ? this.insights.filtersUav : this.insights.defaultFilters;
        useLocalStorage().setLocalStorage('filtersUav', JSON.stringify(this.insights.filtersUav));
      } else {
        this.insights.filters =
          this.insights.filtersAic.length > 0 ? this.insights.filtersAic : this.insights.defaultFilters;
        useLocalStorage().setLocalStorage('filtersAic', JSON.stringify(this.insights.filtersAic));
      }
    },

    async fetchAssetsInsightsCasesInsightsFilters(currentInsightTableType: any) {
      const insightTitles: any = await API.Insight.getInsightTitlesByTenant();
      const payload = [
        filterUtils().generateFilterFromStringArray('Insight Name', insightTitles.data),
        filterUtils().generateFilterFromEnum('Insight Status', insightStatus),
        filterUtils().generateFilterFromStringArray('Insight State', insightState),
      ];

      const isSeverityColumn =
        useTenantStore().config.aic_topinsights_table_columns.filter((obj: any) => obj.id === 'severity').length > 0;

      if (isSeverityColumn) {
        const severityEnum = useTenantStore().config.insightPreferences
          ? filterUtils().createEnumFromGuidance(useTenantStore().config.insightPreferences.guidance)
          : insightSeverityEnum;
        payload.push(filterUtils().generateFilterFromEnum('Guidance', severityEnum));
      }

      this.insights.filters = payload;

      const filtersCopy = JSON.parse(JSON.stringify(payload));
      this.insights.defaultFilters = filtersCopy;

      if (currentInsightTableType === insightTableType.AIC) {
        this.insights.filtersAic = filtersCopy;
        useLocalStorage().setLocalStorage('filtersAic', JSON.stringify(filtersCopy));
      } else {
        this.insights.filtersUav = filtersCopy;
        useLocalStorage().setLocalStorage('filtersUav', JSON.stringify(filtersCopy));
      }
      return payload;
    },
    async fetchAssetsInsightsCasesInsightsKpi(query: string): Promise<void> {
      const lastDetectedLowerBound = new Date();
      lastDetectedLowerBound.setDate(lastDetectedLowerBound.getDate() - 5);

      const dataObj = {
        insightActionQuery: {
          insightActions: ['NEW'],
        },
        insightQuery: {
          lastDetectedLowerBound: lastDetectedLowerBound.toISOString(),
        },
      };

      this.insights.kpi = (await API.Insight.queryAssetInsightsCount(query, dataObj)).data;
    },
    async fetchAssetsInsightsCasesAssetsKpi(query: string) {
      this.assets.kpi = (await API.Assets.getAssetCountsByAttributes(query)).data;
    },
    async fetchAssetsInsightsCasesCasesKpi(query: string, newCases = 0) {
      const dataObjCase = {
        caseQuery: {
          caseStatuses: ['OPEN'],
        },
      };
      this.cases.kpi = (await API.Assets.findCaseAssetsCountByTenantIdAndAssetAttributes(query, dataObjCase)).data;
      this.cases.newKpi = newCases;
    },
    setPerPage(payload: number) {
      this.perPage = payload;
    },
    fetchAllKpis(userSavedFilterId: string) {
      const query = buildQuery({
        userSavedFilterId: userSavedFilterId,
      });

      this.fetchAssetsInsightsCasesCasesKpi(query);
      this.fetchAssetsInsightsCasesAssetsKpi(query);
      this.fetchAssetsInsightsCasesInsightsKpi(query);
    },
    async setInsightStatusNew() {
      this.setToAll = false;
      localStorage.setItem('lastVisitedRoute', '/assetsInsightsCases/insights');
      const query = buildQuery({
        responseFormat: 'page',
        userSavedFilterId: useUserFiltersStore().getUserCurrentSelectedFilters.userSavedFilterId,
      });
      await this.fetchAssetsInsightsCasesInsightsKpi(query);
      await this.setAssetsInsightsCasesInsightsToNew();
    },
    async fetchAssetsInsightsCasesCasesFilters() {
      const payload = [filterUtils().generateFilterFromStringArray('Case Status', caseStatus)];
      this.cases.filterCasesUav = JSON.parse(JSON.stringify(payload));
      this.cases.filters = JSON.parse(JSON.stringify(payload));
      return payload;
    },
    setCaseStatusOpen() {
      this.setCasesToAll = false;
      localStorage.setItem('lastVisitedRoute', '/assetsInsightsCases/cases');
      this.cases.filters.forEach((filter: any) => {
        if (filter.tag === 'Case Status') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Open') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Open'];
        }
      });
    },
    setUAVCaseStatusOpen() {
      this.cases.filterCasesUav?.forEach((filter: any) => {
        if (filter.tag === 'Case Status') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Open') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Open'];
        }
      });
    },
    setAssetsInsightsCasesCasesToAll() {
      this.setCasesToAll = true;
      localStorage.setItem('lastVisitedRoute', '/assetsInsightsCases/cases');
      this.cases.filters.forEach((filter: any) => {
        filter.options.forEach((option: any) => {
          option.selected = false;
        });
        filter.selectedItems = [];
      });
    },
    async fetchAssetInsightSummaryFilters(insightTableType: any) {
      const payload = [
        filterUtils().generateFilterFromEnum('Insight Status', insightStatus),
        filterUtils().generateFilterFromStringArray('Insight State', insightState),
      ];

      this.assetInsightSummaryFilter = payload;

      useLocalStorage().setLocalStorage('filtersAssetInsightSummary', JSON.stringify(payload));
      return payload;
    },
    setAssetInsightSummaryFilterToNew() {
      this.assetInsightSummaryFilter.forEach((filter: any) => {
        if (filter.tag === 'Insight Status') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Awaiting Review') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Awaiting Review'];
        } 
        else if (filter.tag === 'Insight State') {
          filter.options.forEach((option: any) => {
            if (option.title === 'Active') {
              option.selected = true;
            }
          });
          filter.selectedItems = ['Active'];
        } 
        else {
          filter.options.forEach((option: any) => {
            option.selected = false;
          });
          filter.selectedItems = [];
        }
      });
      useLocalStorage().setLocalStorage('filtersAssetInsightSummary', JSON.stringify(this.assetInsightSummaryFilter));
    }
  },

  getters: {
    insightsStartDates: (state: AssetsInsightsCasesState) => {
      return {
        insightStartDateFrom: state.insights.insightStartDateFrom,
        insightStartDateTo: state.insights.insightStartDateTo,
      };
    },
    insightsNames: (state: AssetsInsightsCasesState) => {
      let insightsNames = [] as string[] | number[];
      if (state.insights && state.insights.filters) {
        state.insights.filters.forEach((filter) => {
          if (filter.tag === 'Insight Name') {
            insightsNames = filter.selectedItems;
          }
        });
      }
      return insightsNames;
    },
    getSetToAll: (state) => {
      return state.setToAll;
    },
    insightsActions: (state: AssetsInsightsCasesState) => {
      let insightsActions = [] as string[];
      if (state.insights && state.insights.filters) {
        state.insights.filters.forEach((filter) => {
          if (filter.tag === 'Insight Status') {
            insightsActions = filter.selectedItems.map(function (x) {
              return x === 'Awaiting Review' ? 'NEW' : x.toString().toUpperCase().replace(/ /g, '_');
            });
          }
        });
      }
      return insightsActions;
    },
    insightsState: (state: AssetsInsightsCasesState) => {
      let insightState = undefined;
      if (state.insights && state.insights.filters) {
        state.insights.filters.forEach((filter) => {
          if (filter.tag === 'Insight State') {
            if (filter.selectedItems.length === 0) {
              return;
            }
            filter.options.forEach((option: any) => {
              if (option.selected && option.title === 'Active') {
                insightState = 'activeOnTs';
              } else if (option.selected && option.title === 'Inactive') {
                insightState = 'inactiveOnTs';
              }
            });
          }
        });
      }
      return insightState;
    },
    insightsSeverity: (state: AssetsInsightsCasesState) => {
      let insightsSeverity = [] as any[] | number[];
      if (state.insights && state.insights.filters) {
        state.insights.filters.forEach((filter) => {
          if (filter.tag === 'Guidance') {
            if (useTenantStore().config.insightPreferences) {
              insightsSeverity = filter.selectedItems.map((description: any) => {
                let selectedKey = '';
                for (const [key, value] of Object.entries(useTenantStore().config.insightPreferences.guidance)) {
                  if (value.description === description) {
                    selectedKey = key;
                  }
                }
                return selectedKey !== ''
                  ? selectedKey
                  : Object.keys(insightSeverityEnum)[Object.values(insightSeverityEnum).indexOf(description)];
              });
            } else {
              insightsSeverity = filter.selectedItems.map(function (value: any) {
                return Object.keys(insightSeverityEnum)[Object.values(insightSeverityEnum).indexOf(value)];
              });
            }
          }
        });
      }
      return insightsSeverity;
    },
    insightsFiltersApplied: (state: AssetsInsightsCasesState) => {
      let filtersApplied = false;
      if (state.insights && state.insights.filters) {
        state.insights.filters.forEach((filter) => {
          if (filter.selectedItems.length > 0) {
            filtersApplied = true;
          }
        });
      }
      return filtersApplied;
    },
    caseStatus: (state: AssetsInsightsCasesState) => {
      let caseStatus = [] as string[] | number[];
      if (state.cases && state.cases.filters) {
        state.cases.filters.forEach((filter) => {
          if (filter.tag === 'Case Status') {
            caseStatus = filter.selectedItems;
          }
        });
      }
      return caseStatus;
    },
    uavCaseStatus: (state: AssetsInsightsCasesState) => {
      let caseStatus = [] as string[] | number[];
      if (state.cases && state.cases.filterCasesUav) {
        state.cases.filterCasesUav?.forEach((filter) => {
          if (filter.tag === 'Case Status') {
            caseStatus = filter.selectedItems;
          }
        });
      }
      return caseStatus;
    },
    getSetToCaseAll: (state) => {
      return state.setCasesToAll;
    },
  },
  persist: {
    paths: [
      'insights.filters',
      'insights.filtersAic',
      'insights.filtersUav',
      'assets.filters',
      'cases.filters',
      'perPage',
      'setCasesToAll',
      'setToAll',
      'cases.filterCasesUav'
    ],
  },
});
