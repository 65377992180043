import Client from '@/api/client/httpClient';
import type { APIListResponse, APIResponse } from '@/api/client/client';

export default class RemoteDiagnosticApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ASSET_UI) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  queryForAllActiveFaultsCount(query: string) {
    return this.instance.post<APIListResponse<any>>(`/asset-ui/v1/faults/active/count${query}`);
  }

  queryForAllActiveFaults(query: string) {
    return this.instance.post<APIResponse<number>>(`/asset-ui/v1/assets/faults/active/search${query}`);
  }

  queryForAssetActiveFaultsCount(query: string, assetId: string) {
    return this.instance.post<APIListResponse<any>>(`/asset-ui/v4/assets/${assetId}/faults/active/count${query}`);
  }

  queryForAssetActiveFaults(query: string, assetId: string) {
    return this.instance.post<APIResponse<number>>(`/asset-ui/v1/assets/${assetId}/faults/active/search${query}`);
  }
}